import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: "auto",
  },
}));

const Logo = () => {
  const classes = useStyles();
  const branding = process.env.GATSBY_BRANDING;

  switch (branding) {
    case "stellantis":
      return (
        <StaticImage
          width={200}
          src="../../media/images/stell-logo-thg.png"
          quality={100}
          alt="M3E"
          placeholder="none"
          formats={["auto", "webp", "avif"]}
          className={classes.logo}
        />
      );
    case "public":
      return (
        <StaticImage
          width={100}
          src="../../media/images/m3eThgLogo.png"
          quality={100}
          alt="M3E"
          placeholder="none"
          formats={["auto", "webp", "avif"]}
          className={classes.logo}
        />
      );
    default:
      return null;
  }
};

export default Logo;
