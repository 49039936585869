import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "../Link";
import { Divider, makeStyles } from "@material-ui/core";
import { isLoggedIn } from "../../api";

const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.primary.main,
    minHeight: "100vh",
    color: theme.palette.primary.light,
    width: "250px",
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    border: "0.5px solid #fff",
  },
}));

export default function MobileMenu({
  state,
  setState,
  navigation,
  authenticatedButtons,
  unauthenticatedButtons,
}) {
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={state}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <div
        className={classes.list}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {navigation.map(({ name, href, external }, index) => (
            <ListItem
              key={index}
              button
              component={Link}
              href={href}
              underline="none"
              external={external}
            >
              {/* <ListItemIcon></ListItemIcon> */}
              <ListItemText primary={name} />
            </ListItem>
          ))}
          <Divider className={classes.divider} />
          {(isLoggedIn() ? authenticatedButtons : unauthenticatedButtons).map(
            (button, index) => (
              <ListItem
                key={index}
                button
                underline="none"
                component={Link}
                href={button.href}
              >
                <ListItemText primary={button.name} />
              </ListItem>
            )
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
}
